import React from "react";
import classes from "./Header.module.css";
const NotIcon = (props) => {
  return (
    <svg
      onClick={props.onClick}
      width="20"
      height="22"
      viewBox="0 0 20 22"
      className={classes.icon}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.35416 20C8.0593 20.6224 8.98554 21 9.99999 21C11.0144 21 11.9407 20.6224 12.6458 20M16 7C16 5.4087 15.3678 3.88258 14.2426 2.75736C13.1174 1.63214 11.5913 1 9.99999 1C8.40869 1 6.88257 1.63214 5.75735 2.75736C4.63213 3.88258 3.99999 5.4087 3.99999 7C3.99999 10.0902 3.22046 12.206 2.34965 13.6054C1.61512 14.7859 1.24785 15.3761 1.26131 15.5408C1.27622 15.7231 1.31485 15.7926 1.46176 15.9016C1.59445 16 2.19258 16 3.38884 16H16.6111C17.8074 16 18.4055 16 18.5382 15.9016C18.6851 15.7926 18.7238 15.7231 18.7387 15.5408C18.7521 15.3761 18.3849 14.7859 17.6503 13.6054C16.7795 12.206 16 10.0902 16 7Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NotIcon;
