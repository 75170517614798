import React from "react";
import classes from "./Navbar.module.css";
const RegIcon = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      className={classes.icon}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3334 3.33366C12.1083 3.33366 12.4958 3.33366 12.8138 3.41884C13.6765 3.65001 14.3504 4.32388 14.5815 5.18661C14.6667 5.50453 14.6667 5.89202 14.6667 6.66699V14.3337C14.6667 15.7338 14.6667 16.4339 14.3942 16.9686C14.1545 17.439 13.7721 17.8215 13.3017 18.0612C12.7669 18.3337 12.0668 18.3337 10.6667 18.3337H5.33337C3.93324 18.3337 3.23318 18.3337 2.6984 18.0612C2.22799 17.8215 1.84554 17.439 1.60586 16.9686C1.33337 16.4339 1.33337 15.7338 1.33337 14.3337V6.66699C1.33337 5.89202 1.33337 5.50453 1.41856 5.18661C1.64973 4.32388 2.3236 3.65001 3.18633 3.41884C3.50424 3.33366 3.89173 3.33366 4.66671 3.33366M6.00004 5.00033H10C10.4667 5.00033 10.7001 5.00033 10.8784 4.9095C11.0352 4.8296 11.1626 4.70212 11.2425 4.54532C11.3334 4.36706 11.3334 4.1337 11.3334 3.66699V3.00033C11.3334 2.53362 11.3334 2.30026 11.2425 2.122C11.1626 1.9652 11.0352 1.83771 10.8784 1.75782C10.7001 1.66699 10.4668 1.66699 10 1.66699H6.00004C5.53333 1.66699 5.29997 1.66699 5.12171 1.75782C4.96491 1.83771 4.83743 1.9652 4.75753 2.122C4.66671 2.30026 4.66671 2.53362 4.66671 3.00033V3.66699C4.66671 4.1337 4.66671 4.36706 4.75753 4.54532C4.83743 4.70212 4.96491 4.8296 5.12171 4.9095C5.29997 5.00033 5.53333 5.00033 6.00004 5.00033Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RegIcon;
