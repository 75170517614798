import { createSlice } from "@reduxjs/toolkit";

const initalState = {
  user: null,
  token: "",
};

const AuthSlice = createSlice({
  name: "Auth",
  initialState: initalState,
  reducers: {
    adduser(state, action) {
      state.user = {
        email: action.payload.email,
        countryCode: action.payload.countryCode,
        phoneNumber: action.payload.phoneNumber,
        type: action.payload.type,
      };
    },
    addtoken(state, action) {
      state.token = action.payload;
    },
    resetUser(state, action) {
      state.user = null;
      state.token = " ";
    },
  },
});
export const AuthAction = AuthSlice.actions;
export default AuthSlice;
