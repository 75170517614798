import React from "react";
import classes from "./Navbar.module.css";
const PreIcon = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      className={classes.icon}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99996 5.00033L8.74996 8.75033M4.99996 5.00033H2.49996L1.66663 2.50033L2.49996 1.66699L4.99996 2.50033V5.00033ZM16.0491 2.2845L13.8594 4.47418C13.5294 4.8042 13.3644 4.9692 13.3026 5.15948C13.2482 5.32685 13.2482 5.50714 13.3026 5.67451C13.3644 5.86478 13.5294 6.02979 13.8594 6.3598L14.0571 6.55752C14.3872 6.88753 14.5522 7.05254 14.7424 7.11436C14.9098 7.16874 15.0901 7.16874 15.2575 7.11436C15.4477 7.05254 15.6128 6.88753 15.9428 6.55752L17.991 4.50926C18.2116 5.04606 18.3333 5.63399 18.3333 6.25033C18.3333 8.78163 16.2813 10.8337 13.75 10.8337C13.4448 10.8337 13.1466 10.8038 12.8581 10.7469C12.453 10.667 12.2504 10.6271 12.1276 10.6393C11.9971 10.6523 11.9327 10.6719 11.817 10.7338C11.7082 10.792 11.5991 10.9012 11.3808 11.1195L5.41663 17.0836C4.72627 17.774 3.60698 17.774 2.91663 17.0836C2.22627 16.3933 2.22627 15.274 2.91663 14.5836L8.88081 8.61947C9.0991 8.40118 9.20825 8.29204 9.26647 8.18323C9.32837 8.06756 9.34795 8.00322 9.36096 7.87267C9.37319 7.74987 9.33324 7.54731 9.25335 7.14219C9.19645 6.85371 9.16662 6.5555 9.16662 6.25033C9.16662 3.71902 11.2187 1.66699 13.75 1.66699C14.5879 1.66699 15.3733 1.89184 16.0491 2.2845ZM10 12.5003L14.5833 17.0836C15.2736 17.7739 16.3929 17.7739 17.0833 17.0836C17.7736 16.3932 17.7736 15.2739 17.0833 14.5836L13.3127 10.8131C13.0458 10.7878 12.7855 10.7397 12.5339 10.6706C12.2097 10.5816 11.8541 10.6462 11.6163 10.8839L10 12.5003Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PreIcon;
