import {
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Box,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import SalesTableCell from "./SalesTableCell.js";

function SalesTable({ title, rows, shadow }) {
  const renderTableCells = rows.map((row, key) => {
    const tableRows = [];
    const rowKey = `row-${key}`;

    Object.entries(row).map(([cellTitle, cellContent]) =>
      Array.isArray(cellContent)
        ? tableRows.push(
            <SalesTableCell
              key={cellContent[1]}
              title={cellTitle}
              content={cellContent[1]}
              image={cellContent[0]}
              noBorder={key === rows.length - 1}
            />
          )
        : tableRows.push(
            <SalesTableCell
              key={cellContent}
              title={cellTitle}
              content={cellContent}
              noBorder={key === rows.length - 1}
            />
          )
    );

    return <TableRow key={rowKey}>{tableRows}</TableRow>;
  });

  return (
    <TableContainer sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <Table>
        {title ? (
          <TableHead>
            <Box component="tr" width="max-content" display="block" mb={1.5}>
              <Typography variant="h6" component="td">
                {title}
              </Typography>
            </Box>
          </TableHead>
        ) : null}
        <TableBody>{useMemo(() => renderTableCells, [renderTableCells])}</TableBody>
      </Table>
    </TableContainer>
  );
}

SalesTable.defaultProps = {
  title: "",
  rows: [{}],
  shadow: true,
};

SalesTable.propTypes = {
  title: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.object),
  shadow: PropTypes.bool,
};

export default SalesTable;
