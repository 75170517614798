import Header from "components/Shared/Header/Header";
import MobileNav from "components/Shared/Drawer/MobileNav";
import React from "react";
import Navbar from "../components/Shared/Navbar";
import DashboardData from "components/Dashboard/DashboardData/DashboardData";

const Dashboard = () => {
  return (
    <div className="order">
      <div>
        <Navbar height="220vh" />
      </div>
      <div style={{ background: "#E5E5E5", flex: 1 }}>
        <MobileNav />
        <Header name="Dashboard" />
        <DashboardData />
      </div>
    </div>
  );
};

export default Dashboard;
