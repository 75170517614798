import React from "react";
import classes from "./LStill.module.css";
import Image from "../../../assets/images/Creative.png";
import ForwardIcon from "@mui/icons-material/Forward";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
const LStill = () => {
  const navigate = useNavigate();
  return (
    <div className={classes.LStill} id="become">
      <img src={Image} alt="Creative" />
      <div>
        <h2>Sell With Base</h2>
        <p>The easiest Place to sell anything and everything online.</p>
        <a href="https://sell.basekwt.com/sign-up">
          <ForwardIcon fontSize="large" sx={{ diplay: "inline", color: "#2d2d77" }} />
          <Typography variant="h4" component="p">
            Become a vendeor
          </Typography>
        </a>
      </div>
    </div>
  );
};

export default LStill;
