import React from "react";
import classes from "./Navbar.module.css";
const DesIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.icon}
    >
      <path
        d="M7.49984 7.50008H7.50817M12.4998 12.5001H12.5082M13.3332 6.66675L6.6665 13.3334M6.11131 3.18233C6.78117 3.12887 7.4171 2.86546 7.92856 2.4296C9.12211 1.41246 10.8776 1.41246 12.0711 2.4296C12.5826 2.86546 13.2185 3.12887 13.8884 3.18233C15.4515 3.30707 16.6928 4.54837 16.8176 6.11155C16.871 6.78142 17.1345 7.41734 17.5703 7.92881C18.5875 9.12235 18.5875 10.8778 17.5703 12.0714C17.1345 12.5828 16.871 13.2187 16.8176 13.8886C16.6928 15.4518 15.4515 16.6931 13.8884 16.8178C13.2185 16.8713 12.5826 17.1347 12.0711 17.5706C10.8776 18.5877 9.12211 18.5877 7.92856 17.5706C7.4171 17.1347 6.78117 16.8713 6.11131 16.8178C4.54812 16.6931 3.30683 15.4518 3.18208 13.8886C3.12863 13.2187 2.86522 12.5828 2.42935 12.0714C1.41222 10.8778 1.41222 9.12235 2.42935 7.92881C2.86522 7.41734 3.12863 6.78142 3.18208 6.11155C3.30683 4.54837 4.54812 3.30707 6.11131 3.18233ZM7.9165 7.50008C7.9165 7.7302 7.72995 7.91675 7.49984 7.91675C7.26972 7.91675 7.08317 7.7302 7.08317 7.50008C7.08317 7.26996 7.26972 7.08341 7.49984 7.08341C7.72995 7.08341 7.9165 7.26996 7.9165 7.50008ZM12.9165 12.5001C12.9165 12.7302 12.73 12.9167 12.4998 12.9167C12.2697 12.9167 12.0832 12.7302 12.0832 12.5001C12.0832 12.27 12.2697 12.0834 12.4998 12.0834C12.73 12.0834 12.9165 12.27 12.9165 12.5001Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DesIcon;
