import React from "react";
import classes from "./StatBox.module.css";
import { Edit, LocalMall, PeopleAlt, Person } from "@mui/icons-material";
const StatBox = (props) => {
  return (
    <div className={classes.BoxStat}>
      <div className={classes.BoxParent}>
        <span className={classes.Icon} style={{ background: `${props.back}` }}>
          {props.icon ? props.icon : <Edit sx={{ color: "white" }} />}
        </span>
        <p>Total {props.type}</p>
      </div>
      <div className={classes.BoxStatTotal}>
        <p>{props.count}</p>
      </div>
      <p className={classes.Active}>
        {props.percent && <span>+{props.percent}% </span>}Users active today
      </p>
    </div>
  );
};

export default StatBox;
