import LAbout from "components/LandingPage/LandingNav/LAbout/LAbout";
import { LandingNav } from "components/LandingPage/LandingNav/LandingNav";
import LBecome from "components/LandingPage/LBecome/LBecome";
import LFooter from "components/LandingPage/LFooter/LFooter";
import LForm from "components/LandingPage/LForm/LForm";
import LOur from "components/LandingPage/LOur/LOur";
import LStill from "components/LandingPage/LStill/LStill";
import React from "react";

const LandingPage = () => {
  return (
    <div style={{ background: "#fff" }}>
      <LandingNav />
      <LAbout />
      <div style={{ width: "100%" }}>
        <LOur />
        <LBecome />
        <LStill />
      </div>
      <LForm />
      <LFooter />
    </div>
  );
};

export default LandingPage;
