import { Drawer, Box, Typography, IconButton } from "@mui/material";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import classes from "./MobileNav.module.css";
import React from "react";
import { useNavigate } from "react-router-dom";
const MobileNav = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const Navigate = useNavigate();
  return (
    <>
      <IconButton
        onClick={() => setIsDrawerOpen(true)}
        size="large"
        edge="start"
        color="inherit"
        aria-label="logo"
        className={classes.MobileNav}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <Box p={2} width="250px" role="presentation">
          <Typography variant="h6" component="div">
            Base
          </Typography>
          <nav className={classes.NavMobile}>
            <li onClick={() => Navigate("/admin/dashboard")}>Dashboard</li>
            <li onClick={() => Navigate("/admin/product")}>Product</li>
            <li onClick={() => Navigate("/admin/orders")}>Orders</li>
            <li onClick={() => Navigate("/admin/wishlist")}>Wishlist</li>
            <li onClick={() => Navigate("/admin/registries")}>Registries</li>
            <li onClick={() => Navigate("/admin/mangeuser")}>Customer</li>
            <li onClick={() => Navigate("")}>Seller</li>
            <li onClick={() => Navigate("")}>Sub Admin</li>
            <li onClick={() => Navigate("/admin/discount")}>Discount</li>
            <li onClick={() => Navigate("/admin/ManageStores")}>Manage Stores</li>
            <li onClick={() => Navigate("/admin/ManageCity")}>Manage City</li>
            <li onClick={() => Navigate("/admin/ManageCountry")}>Manage Country</li>
            <li onClick={() => Navigate("/admin/Manageonboarding")}>Manage OnBoarding</li>
            <li onClick={() => Navigate("/admin/ManageCategories")}>Manage Categories</li>
            <li onClick={() => Navigate("/admin/ManageColor")}>Manage Color</li>
            <li onClick={() => Navigate("/admin/ManageSize")}>Manage Size</li>
            <li onClick={() => Navigate("/admin/ManageTag")}>Manage Tags</li>
            <li onClick={() => Navigate("/admin/faq")}>FAQ</li>
            <li onClick={() => Navigate("/admin/privacy-policy")}>Privacy And Policy</li>
            <li onClick={() => Navigate("/admin/terms-and-conditions")}>Terms And Conditions</li>
          </nav>
        </Box>
      </Drawer>
    </>
  );
};

export default MobileNav;
