import React from "react";
import classes from "./Navbar.module.css";
const Icon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={classes.icon}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7877 13.3336C15.4468 12.3888 15.8333 11.2397 15.8333 10.0003C15.8333 9.57125 15.787 9.153 15.699 8.75029M5.2122 13.3337C4.55314 12.3888 4.16663 11.2397 4.16663 10.0003C4.16663 6.77866 6.7783 4.16699 9.99996 4.16699C10.3517 4.16699 10.6962 4.19813 11.0309 4.2578M13.7499 6.25033L9.99988 10.0003M18.3333 10.0003C18.3333 14.6027 14.6023 18.3337 9.99996 18.3337C5.39759 18.3337 1.66663 14.6027 1.66663 10.0003C1.66663 5.39795 5.39759 1.66699 9.99996 1.66699C14.6023 1.66699 18.3333 5.39795 18.3333 10.0003ZM10.8333 10.0003C10.8333 10.4606 10.4602 10.8337 9.99996 10.8337C9.53972 10.8337 9.16662 10.4606 9.16662 10.0003C9.16662 9.54009 9.53972 9.16699 9.99996 9.16699C10.4602 9.16699 10.8333 9.54009 10.8333 10.0003Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Icon;
