import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { AuthAction } from "store/auth.js";
import { QueryClient, QueryClientProvider } from "react-query";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Dashboard from "pages/Dashboard.jsx";
import Login from "pages/Login.jsx";
import Spinner from "components/Shared/Spinner/Spinner.jsx";
import NotFound from "pages/NotFound.jsx";
import LandingPage from "pages/LandingPage.jsx";

const ManageOnboarding = React.lazy(() =>
  import("components/Preferences/ManageOnboarding/ManageOnboarding.jsx")
);
const Addon = React.lazy(() =>
  import("components/Preferences/ManageOnboarding/AddOnboarding/Addon.jsx")
);
const Updateon = React.lazy(() =>
  import("components/Preferences/ManageOnboarding/UpdateOnboarding/UpdateOn.jsx")
);
const ProductReject = React.lazy(() =>
  import("components/Product/productReject/ProductReject.jsx")
);

const EditTerm = React.lazy(() => import("components/SettingPage/UserTerms/EditTerm/EditTerm.jsx"));
const AddTerm = React.lazy(() => import("components/SettingPage/UserTerms/AddTerm/AddTerm.jsx"));
const EditOp = React.lazy(() => import("components/SettingPage/UserOption/EditOp/EditOp.jsx"));
const EdidAPPContent = React.lazy(() =>
  import("components/SettingPage/AppContent/EditAppContent/EdidAPPContent.jsx")
);
const UserTerms = React.lazy(() => import("components/SettingPage/UserTerms/UserTerms.jsx"));
const AddDiscount = React.lazy(() => import("components/Discount/AddDiscount/AddDiscount.jsx"));
const EditDiscount = React.lazy(() => import("components/Discount/EditDiscount/EditDiscount.jsx"));
const UpdateDiscount = React.lazy(() =>
  import("components/Discount/UpdateDiscount/UpdateDiscount.jsx")
);
const ResetModal = React.lazy(() => import("components/ForgetPassword/ResetModal/ResetModal.jsx"));
const ResetPassword = React.lazy(() =>
  import("components/ForgetPassword/ResetPassword/ResetPassword.jsx")
);
const CustomerCard = React.lazy(() =>
  import("components/ManageUser/CustomerCard/CustomerCard.jsx")
);
const UserView = React.lazy(() => import("components/ManageUser/Userview/UserView.jsx"));
const OrderView = React.lazy(() => import("components/Order/OrderView/OrderView.jsx"));
const AddCat = React.lazy(() =>
  import("components/Preferences/ManageCategories/AddCat/AddCat.jsx")
);
const EditCat = React.lazy(() =>
  import("components/Preferences/ManageCategories/EditCat/EditCat.jsx")
);
const ManageCategories = React.lazy(() =>
  import("components/Preferences/ManageCategories/ManageCategories.jsx")
);
const AddColor = React.lazy(() =>
  import("components/Preferences/ManageColor/AddColor/AddColor.jsx")
);
const EditColor = React.lazy(() =>
  import("components/Preferences/ManageColor/EditColor/EditColor.jsx")
);
const ManageColor = React.lazy(() => import("components/Preferences/ManageColor/ManageColor.jsx"));
const AddSize = React.lazy(() => import("components/Preferences/ManageSize/AddSize/AddSize.jsx"));
const EditSize = React.lazy(() =>
  import("components/Preferences/ManageSize/EditSize/EditSize.jsx")
);
const ManageSize = React.lazy(() => import("components/Preferences/ManageSize/ManageSize.jsx"));
const AddStores = React.lazy(() =>
  import("components/Preferences/ManageStores/AddStores/AddStores.jsx")
);
const EditStores = React.lazy(() =>
  import("components/Preferences/ManageStores/EditStores/EditStores.jsx")
);
const ManageStores = React.lazy(() =>
  import("components/Preferences/ManageStores/ManageStores.jsx")
);
const AddTag = React.lazy(() => import("components/Preferences/ManageTag/AddTag/AddTag.jsx"));
const EditTag = React.lazy(() => import("components/Preferences/ManageTag/EditTag/EditTag.jsx"));
const ManageTag = React.lazy(() => import("components/Preferences/ManageTag/ManageTag.jsx"));
const EditProduct = React.lazy(() => import("components/Product/EditProduct/EditProduct.jsx"));
const ProductDetails = React.lazy(() =>
  import("components/Product/ProductDetails/ProductDetails.jsx")
);
const ProductView = React.lazy(() => import("components/Product/ProductView/ProductView.jsx"));
const RegView = React.lazy(() => import("components/Registery/RegView/RegView.jsx"));
const AppContent = React.lazy(() => import("components/SettingPage/AppContent/AppContent.jsx"));
const AppContentForm = React.lazy(() =>
  import("components/SettingPage/AppContent/AppContentForm/AppContentForm.jsx")
);
const AppContentText = React.lazy(() =>
  import("components/SettingPage/AppContent/AppContentForm/AppContentText/AppContentText.jsx")
);
const UserOption = React.lazy(() => import("components/SettingPage/UserOption/UserOption.jsx"));
const WishlistView = React.lazy(() => import("components/Wishlist/WhislistView/WishlistView.jsx"));
const Discount = React.lazy(() => import("pages/Discount.jsx"));
const ForgetPassword = React.lazy(() => import("pages/ForgetPassword.jsx"));
const MangeUser = React.lazy(() => import("pages/MangeUser.jsx"));
const Orders = React.lazy(() => import("pages/Orders.jsx"));
const Product = React.lazy(() => import("pages/Product.jsx"));
const Registries = React.lazy(() => import("pages/Registries.jsx"));
const Wishlist = React.lazy(() => import("pages/Wishlist.jsx"));
const ManageCountry = React.lazy(() =>
  import("components/Preferences/ManageCountry/ManageCountry.jsx")
);
const AddCountry = React.lazy(() =>
  import("components/Preferences/ManageCountry/AddCountry/AddCountry.jsx")
);
const EditCountry = React.lazy(() =>
  import("components/Preferences/ManageCountry/EditCountry/EditCounry.jsx")
);
const ManageCity = React.lazy(() => import("components/Preferences/ManageCity/ManageCity.jsx"));
const AddCity = React.lazy(() => import("components/Preferences/ManageCity/AddCity/AddCity.jsx"));
const Notification = React.lazy(() => import("components/Dashboard/notification/Notification.jsx"));
const Review = React.lazy(() => import("pages/Review.jsx"));
const StockReject = React.lazy(() =>
  import("components/Product/StockProduct/StockReject/StockReject.jsx")
);
const EditCity = React.lazy(() =>
  import("components/Preferences/ManageCity/EditCity/EditCity.jsx")
);
const StockProduct = React.lazy(() => import("components/Product/StockProduct/StockProduct.jsx"));
function App() {
  const dispatch = useDispatch();

  useMemo(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (storedData && storedData.token) {
      dispatch(
        AuthAction.adduser({
          email: storedData.user.email,
          countryCode: storedData.user.countryCode,
          phoneNumber: storedData.user.phoneNumber,
          type: storedData.user.type,
        })
      );
      dispatch(AuthAction.addtoken(storedData.token));
    }
  }, []);
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/admin" element={<Login />} />
          <Route
            path="admin/product/producReject/:id"
            element={
              <React.Suspense fallback={<Spinner />}>
                <ProductReject />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/Manageonboarding"
            element={
              <React.Suspense fallback={<Spinner />}>
                <ManageOnboarding />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/Manageonboarding/addonboarding"
            element={
              <React.Suspense fallback={<Spinner />}>
                <Addon />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/Manageonboarding/updateonboarding/:id"
            element={
              <React.Suspense fallback={<Spinner />}>
                <Updateon />
              </React.Suspense>
            }
          />
          <Route
            path="/forget-password"
            element={
              <React.Suspense fallback={<Spinner />}>
                <ForgetPassword />
              </React.Suspense>
            }
          >
            <Route
              path="confirm"
              element={
                <React.Suspense fallback={<Spinner />}>
                  <ResetPassword />
                </React.Suspense>
              }
            />
            <Route
              path="modal"
              element={
                <React.Suspense fallback={<Spinner />}>
                  <ResetModal />
                </React.Suspense>
              }
            />
          </Route>
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route
            path="/allnotifications"
            element={
              <React.Suspense fallback={<Spinner />}>
                <Notification />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/product"
            element={
              <React.Suspense fallback={<Spinner />}>
                <Product />
              </React.Suspense>
            }
          />
          <Route
            path="/stockProduct"
            element={
              <React.Suspense fallback={<Spinner />}>
                <StockProduct />
              </React.Suspense>
            }
          />
          <Route
            path="/stockReject/:id"
            element={
              <React.Suspense fallback={<Spinner />}>
                <StockReject />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/product/detail/:id"
            element={
              <React.Suspense fallback={<Spinner />}>
                <ProductDetails />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/product/view"
            element={
              <React.Suspense fallback={<Spinner />}>
                <ProductView />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/product/edit"
            element={
              <React.Suspense fallback={<Spinner />}>
                <EditProduct />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/orders"
            element={
              <React.Suspense fallback={<Spinner />}>
                <Orders />
              </React.Suspense>
            }
          />

          <Route
            path="/admin/orders/view/:id"
            element={
              <React.Suspense fallback={<Spinner />}>
                <OrderView />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/wishlist"
            element={
              <React.Suspense fallback={<Spinner />}>
                <Wishlist />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/wishlist/view"
            element={
              <React.Suspense fallback={<Spinner />}>
                <WishlistView />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/registries"
            element={
              <React.Suspense fallback={<Spinner />}>
                <Registries />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/registries/view"
            element={
              <React.Suspense fallback={<Spinner />}>
                <RegView />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/mangeuser"
            element={
              <React.Suspense fallback={<Spinner />}>
                <MangeUser />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/mangeuser/cutomer/:id"
            element={
              <React.Suspense fallback={<Spinner />}>
                <CustomerCard />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/mangeuser/view"
            element={
              <React.Suspense fallback={<Spinner />}>
                <UserView />
              </React.Suspense>
            }
          />
          <Route
            path="/review"
            element={
              <React.Suspense fallback={<Spinner />}>
                <Review />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/discount"
            element={
              <React.Suspense fallback={<Spinner />}>
                <Discount />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/discount/adddiscount"
            element={
              <React.Suspense fallback={<Spinner />}>
                <AddDiscount />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/discount/editdiscount"
            element={
              <React.Suspense fallback={<Spinner />}>
                <EditDiscount />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/discount/updatediscount"
            element={
              <React.Suspense fallback={<Spinner />}>
                <UpdateDiscount />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/ManageCategories"
            element={
              <React.Suspense fallback={<Spinner />}>
                <ManageCategories />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/ManageCategories/addcat"
            element={
              <React.Suspense fallback={<Spinner />}>
                <AddCat />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/ManageCategories/editcat/:id"
            element={
              <React.Suspense fallback={<Spinner />}>
                <EditCat />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/ManageStores"
            element={
              <React.Suspense fallback={<Spinner />}>
                <ManageStores />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/ManageStores/addstorie"
            element={
              <React.Suspense fallback={<Spinner />}>
                <AddStores />
              </React.Suspense>
            }
          />
          <Route
            path="ManageStores/editstorie"
            element={
              <React.Suspense fallback={<Spinner />}>
                <EditStores />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/ManageSize"
            element={
              <React.Suspense fallback={<Spinner />}>
                <ManageSize />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/ManageSize/addsize"
            element={
              <React.Suspense fallback={<Spinner />}>
                <AddSize />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/ManageSize/editsize/:id"
            element={
              <React.Suspense fallback={<Spinner />}>
                <EditSize />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/ManageCountry"
            element={
              <React.Suspense fallback={<Spinner />}>
                <ManageCountry />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/ManageCountry/addcountry"
            element={
              <React.Suspense fallback={<Spinner />}>
                <AddCountry />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/ManageCountry/editcountry/:id"
            element={
              <React.Suspense fallback={<Spinner />}>
                <EditCountry />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/ManageCity"
            element={
              <React.Suspense fallback={<Spinner />}>
                <ManageCity />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/ManageCity/addcity"
            element={
              <React.Suspense fallback={<Spinner />}>
                <AddCity />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/ManageCity/editcity/:id"
            element={
              <React.Suspense fallback={<Spinner />}>
                <EditCity />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/ManageTag"
            element={
              <React.Suspense fallback={<Spinner />}>
                <ManageTag />
              </React.Suspense>
            }
          />

          <Route
            path="/admin/ManageTag/addtag"
            element={
              <React.Suspense fallback={<Spinner />}>
                <AddTag />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/ManageTag/edittag/:id"
            element={
              <React.Suspense fallback={<Spinner />}>
                <EditTag />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/ManageColor"
            element={
              <React.Suspense fallback={<Spinner />}>
                <ManageColor />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/ManageColor/addcolor"
            element={
              <React.Suspense fallback={<Spinner />}>
                <AddColor />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/ManageColor/editcolor/:id"
            element={
              <React.Suspense fallback={<Spinner />}>
                <EditColor />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/faq"
            element={
              <React.Suspense fallback={<Spinner />}>
                <AppContent />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/faq/appform"
            element={
              <React.Suspense fallback={<Spinner />}>
                <AppContentForm />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/faq/updateform/:id"
            element={
              <React.Suspense fallback={<Spinner />}>
                <EdidAPPContent />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/privacy-policy/add"
            element={
              <React.Suspense fallback={<Spinner />}>
                <AppContentText />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/privacy-policy/update/:id"
            element={
              <React.Suspense fallback={<Spinner />}>
                <EditOp />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/privacy-policy"
            element={
              <React.Suspense fallback={<Spinner />}>
                <UserOption />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/terms-and-conditions"
            element={
              <React.Suspense fallback={<Spinner />}>
                <UserTerms />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/terms-and-conditions/add"
            element={
              <React.Suspense fallback={<Spinner />}>
                <AddTerm />
              </React.Suspense>
            }
          />
          <Route
            path="/admin/terms-and-conditions/update/:id"
            element={
              <React.Suspense fallback={<Spinner />}>
                <EditTerm />
              </React.Suspense>
            }
          />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </QueryClientProvider>
  );
}

export default App;
