import React from "react";
import classes from "./LAbout.module.css";
import One from "../../../../assets/images/photo1.png";
import Mobile from "../../../../assets/images/Mobile.png";
import Tow from "../../../../assets/images/photo2.png";
const LAbout = () => {
  return (
    <>
      <div className={classes.LAbout} id="about">
        <div className={classes.First}>
          <div className={classes.SImg}>
            <img src={One} />
          </div>
          <h2>A brand</h2>
          <div className={classes.ImgAText}>
            <img src={One} /> <h2>new</h2>{" "}
          </div>
        </div>
        <div className={classes.mobileImg}>
          <img src={Mobile} />
        </div>
        <div className={classes.Second}>
          <img src={Tow} />
          <h2>way to shop </h2>
        </div>
        <h2 className={classes.MobileBrand}>A brand new way to shop </h2>
      </div>
      <div className={classes.comming}>
        <h3>Coming Soon</h3>
        <h3>Coming Soon</h3>
        <h3>Coming Soon</h3>
        <h3>Coming Soon</h3>
      </div>
    </>
  );
};

export default LAbout;
