import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Stack,
  Menu,
  MenuItem,
} from "@mui/material";
import Apple from "../../../assets/images/Apple.png";
import Android from "../../../assets/images/andriod.png";
import classes from "./LandingNav.module.css";
import { useState } from "react";
import MenuNav from "../MenuNav/MenuNav";
import NavLogo from "../../../assets/images/NavLogo.png";
import { useNavigate } from "react-router-dom";
export const LandingNav = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={classes.Top}></div>
      <AppBar
        postion="fixed"
        color="transparent"
        sx={{ backgroundColor: "#Fff", marginTop: "-0px" }}
        className={classes.navbar}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="logo"
            sx={{ marginRight: "20px" }}
            onClick={() => {
              navigate("/admin");
            }}
          >
            <img src={NavLogo} alt="logoNav" />
          </IconButton>

          <Stack direction="row" spacing={1} sx={{ flexGrow: 1 }}>
            <Button
              sx={{
                fontFamily: "Source Sans Pro, sans-serif",
                fontWeight: 500,
                color: "#111827",
                fontSize: "17px",
                textTransform: "capitalize",
              }}
              color="inherit"
              href="#about"
            >
              About us
            </Button>
            <Button
              sx={{
                fontFamily: "Source Sans Pro, sans-serif",
                fontWeight: 500,
                color: "#111827",
                fontSize: "17px",
                textTransform: "capitalize",
              }}
              color="inherit"
              href="#ourapp"
            >
              Our app
            </Button>
            <Button
              sx={{
                fontFamily: "Source Sans Pro, sans-serif",
                fontWeight: 500,
                color: "#111827",
                fontSize: "17px",
                textTransform: "capitalize",
              }}
              color="inherit"
              href="#become"
            >
              Become a vendor
            </Button>
            <Button
              sx={{
                fontFamily: "Source Sans Pro, sans-serif",
                fontWeight: 500,
                color: "#111827",
                fontSize: "17px",
                textTransform: "capitalize",
              }}
              color="inherit"
              href="#contact"
            >
              Contact Us
            </Button>
          </Stack>
          <Stack direction="row" spacing={2} className={classes.phone}>
            <img src={Apple} alt="Apple" />
            <img src={Android} alt="Apple" />
          </Stack>
          <Stack direction="row" className={classes.menuPhone}>
            <MenuNav />
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  );
};
