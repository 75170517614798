import React from "react";

const DataICon = () => {
  return (
    <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4 15C11.4 17.7614 13.6386 20 16.4 20C19.1614 20 21.4 17.7614 21.4 15C21.4 12.2386 19.1614 10 16.4 10C13.6386 10 11.4 12.2386 11.4 15ZM11.4 15C11.4 13.8742 11.7721 12.8353 12.4 11.9995V3M11.4 15C11.4 15.8254 11.6 16.604 11.9542 17.2901C11.1117 18.0018 9.16587 18.5 6.90002 18.5C3.86246 18.5 1.40002 17.6046 1.40002 16.5V3M12.4 3C12.4 4.10457 9.93759 5 6.90002 5C3.86246 5 1.40002 4.10457 1.40002 3M12.4 3C12.4 1.89543 9.93759 1 6.90002 1C3.86246 1 1.40002 1.89543 1.40002 3M1.40002 12C1.40002 13.1046 3.86246 14 6.90002 14C9.08903 14 10.9793 13.535 11.8647 12.8618M12.4 7.5C12.4 8.60457 9.93759 9.5 6.90002 9.5C3.86246 9.5 1.40002 8.60457 1.40002 7.5"
        stroke="#F8F9FA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DataICon;
