import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Logo from "assets/images/logo-base.png";
import { set } from "date-fns";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import DesIcon from "./DesIcon";
import Icon from "./Icon";
import classes from "./Navbar.module.css";
import OrderIcon from "./OrderIcon";
import PreIcon from "./PreIcon";
import ProductIcon from "./ProductIcon";
import RegIcon from "./RegIcon";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
const Navbar = (props) => {
  const path = window.location.pathname;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  // const [showProduct, setShowProduct] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  return (
    <>
      <div style={{ width: props.width }} className={classes.navbar}>
        <img src={Logo} alt="logo" />
        <nav>
          <li
            className={path === "/admin/dashboard" ? classes.active : ""}
            onClick={() => {
              navigate("/admin/dashboard");
            }}
          >
            <Icon />
            <NavLink className={classes.a} to="/dashboard">
              Dashboard
            </NavLink>
          </li>

          <li
            className={path === "/admin/product" ? classes.active : ""}
            onClick={() => {
              navigate("/admin/product");
            }}
          >
            <ProductIcon />
            <NavLink className={classes.a}>Product</NavLink>
          </li>

          <li className={path === "/order" ? classes.active : ""}>
            <OrderIcon />
            <NavLink className={classes.a} activeclassname={classes.active} to="/admin/orders">
              Orders
            </NavLink>
          </li>
          <li className={path === "/admin/wishlist" ? classes.active : ""}>
            <StarBorderIcon style={{ width: "20px", height: "23px" }} />
            <NavLink className={classes.a} activeclassname={classes.active} to="/admin/wishlist">
              Wishlist
            </NavLink>
          </li>
          <li className={path === "/admin/registries" ? classes.active : ""}>
            <RegIcon />
            <NavLink className={classes.a} activeclassname={classes.active} to="/admin/registries">
              Registries
            </NavLink>
          </li>
          <li
            className={path === "/admin/mangeuser" ? classes.active : ""}
            onClick={() => {
              setShowUsers((prevState) => !prevState);
            }}
          >
            <PersonOutlineIcon style={{ width: "20px", height: "23px" }} />
            <NavLink className={classes.a}>Users</NavLink>
          </li>
          {showUsers && (
            <div className={classes.sublink}>
              <li
                onClick={() => {
                  navigate("/admin/mangeuser");
                }}
              >
                Customer{" "}
              </li>
              <li
                onClick={() => {
                  navigate("");
                }}
              >
                Seller{" "}
              </li>
              <li
                onClick={() => {
                  navigate("");
                }}
              >
                Sub Admins{" "}
              </li>
            </div>
          )}
          <li
            className={path === "/admin/discount" ? classes.active : ""}
            onClick={() => {
              navigate("/admin/discount");
            }}
          >
            <DesIcon />
            <NavLink className={classes.a} to="/admin/discount">
              Discount
            </NavLink>
          </li>
          <li
            className={path === "/preferences" ? classes.active : ""}
            onClick={() => {
              setShow(!show);
            }}
          >
            <PreIcon />
            Prefrences
          </li>
          {show && (
            <div className={classes.sublink}>
              <li
                onClick={() => {
                  navigate("/admin/ManageStores");
                }}
              >
                Manage Stores{" "}
              </li>
              <li
                onClick={() => {
                  navigate("/admin/Manageonboarding");
                }}
              >
                Manage Onboarding{" "}
              </li>
              <li
                onClick={() => {
                  navigate("/admin/ManageCountry");
                }}
              >
                Manage Country{" "}
              </li>
              <li
                onClick={() => {
                  navigate("/admin/ManageCity");
                }}
              >
                Manage City{" "}
              </li>
              <li
                onClick={() => {
                  navigate("/admin/ManageCategories");
                }}
              >
                Manage Categories{" "}
              </li>
              <li
                onClick={() => {
                  navigate("/admin/ManageColor");
                }}
              >
                Manage Color
              </li>
              <li
                onClick={() => {
                  navigate("/admin/ManageSize");
                }}
              >
                Manage Size{" "}
              </li>
              <li
                onClick={() => {
                  navigate("/admin/ManageTag");
                }}
              >
                Manage Tags
              </li>
            </div>
          )}
          <li
            className={path === "/setting" ? classes.active : ""}
            onClick={() => {
              setShowSetting((prevState) => !prevState);
            }}
          >
            <DesIcon />
            <NavLink className={classes.a}>Setting</NavLink>
          </li>
          {showSetting && (
            <div className={classes.sublink}>
              <li
                onClick={() => {
                  navigate("/admin/faq");
                }}
              >
                FAQ{" "}
              </li>
              <li
                onClick={() => {
                  navigate("/admin/privacy-policy");
                }}
              >
                Privacy Policy{" "}
              </li>
              <li
                onClick={() => {
                  navigate("/admin/terms-and-conditions");
                }}
              >
                Terms and Conditions{" "}
              </li>
            </div>
          )}
        </nav>
      </div>
    </>
  );
};

export default Navbar;
