import React from "react";
import { Box } from "@mui/system";
import Loading from "../../../assets/images/VZvw.gif";
import CircularProgress from "@mui/material/CircularProgress";
const Spinner = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        marginTop: "20px",
        justifyContent: "center",
      }}
    >
      <img src={Loading} alt="Loading" style={{ width: "100px", margin: "auto" }} />
    </Box>
  );
};

export default Spinner;
