import Navbar from "components/Shared/Navbar";
import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useNavigate } from "react-router-dom";
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="order">
      <div>
        <Navbar />
      </div>
      <div
        style={{
          display: "flex",
          //   alignItems: "center",
          justifyContent: "center",
          //   flexDirection: "column",
          //   flexWrap: "wrap",
        }}
      >
        <Alert severity="error" sx={{ alignSelf: "center", margin: "50px 10px", flex: "1" }}>
          <AlertTitle>Error</AlertTitle>
          This page is Not Found —{" "}
          <strong
            onClick={() => {
              navigate(-1);
            }}
            style={{ cursor: "pointer" }}
          >
            go back
          </strong>
        </Alert>
      </div>
    </div>
  );
};

export default NotFound;
