import React from "react";
import classes from "./Navbar.module.css";

const ProductIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={classes.icon}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66663 6.66699H6.67496M1.66663 4.33366L1.66663 8.06242C1.66663 8.47007 1.66663 8.6739 1.71268 8.86571C1.7535 9.03577 1.82084 9.19835 1.91223 9.34747C2.0153 9.51566 2.15942 9.65979 2.44767 9.94804L8.8382 16.3386C9.82824 17.3286 10.3233 17.8236 10.8941 18.0091C11.3962 18.1722 11.9371 18.1722 12.4392 18.0091C13.01 17.8236 13.505 17.3286 14.4951 16.3386L16.3382 14.4954C17.3282 13.5054 17.8233 13.0104 18.0087 12.4395C18.1719 11.9374 18.1719 11.3966 18.0087 10.8944C17.8233 10.3236 17.3282 9.82861 16.3382 8.83856L9.94767 2.44804C9.65942 2.15979 9.51529 2.01566 9.3471 1.91259C9.19798 1.82121 9.0354 1.75387 8.86534 1.71304C8.67353 1.66699 8.46971 1.66699 8.06205 1.66699L4.33329 1.66699C3.39987 1.66699 2.93316 1.66699 2.57664 1.84865C2.26304 2.00844 2.00807 2.2634 1.84828 2.57701C1.66663 2.93353 1.66663 3.40024 1.66663 4.33366ZM7.08329 6.66699C7.08329 6.89711 6.89675 7.08366 6.66663 7.08366C6.43651 7.08366 6.24996 6.89711 6.24996 6.66699C6.24996 6.43687 6.43651 6.25033 6.66663 6.25033C6.89675 6.25033 7.08329 6.43687 7.08329 6.66699Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProductIcon;
