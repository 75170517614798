import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import classes from "./Header.module.css";
import NotIcon from "./NotIcon";
import { AuthAction } from "../../../store/auth";

const Header = (props) => {
  const navigate = useNavigate();
  const name = useSelector((state) => state.auth.user.email);
  const dispatch = useDispatch();
  const logout = () => {
    localStorage.removeItem("userData");
    dispatch(AuthAction.resetUser());
    navigate("/admin");
  };
  const brand = name?.slice(0, 2);
  return (
    <header className={classes.header}>
      <h2>{props.name}</h2>
      <div className={classes.actions}>
        <NotIcon onClick={() => {}} />
        <span>
          <p>{brand ? brand : "ba"}</p>
        </span>
        <p className={classes.brand}>{name ? name : "base"}</p>
        <KeyboardArrowDownIcon sx={{ cursor: "pointer" }} onClick={logout} />
      </div>
    </header>
  );
};

export default Header;
