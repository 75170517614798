import React from "react";
import classes from "./LOur.module.css";
import pepole from "../../../assets/images/ourImages.png";

const LOur = () => {
  return (
    <div className={classes.LOur} id="ourapp">
      <img src={pepole} alt="our Image" />

      <div className="">
        <h2>Discover the world's greatest brands</h2>
        <p>Fan-favorites, future-classics - if they shine, they're on Base.</p>
      </div>
    </div>
  );
};

export default LOur;
