import Logo from "assets/images/logo.png";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthAction } from "store/auth";
import useInput from "utils/hooks/use-input";
import classes from "./Form.module.css";

const Form = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);

  const dispatch = useDispatch();
  const {
    value: email,
    isValid: emailISValid,
    hasError: emailInputHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
  } = useInput((value) => value.includes("@"));
  const {
    value: password,
    isValid: passwordIsValid,
    hasError: passwordInputHassError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: resetPassword,
  } = useInput((value) => value.length >= 8);
  let formIsValid = false;
  if (emailISValid && passwordIsValid) {
    formIsValid = true;
  }
  const ForgetHandler = () => {
    navigate("/admin/forget-password");
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();
    if (!emailISValid) {
      return;
    }
    if (!passwordIsValid) {
      return;
    }
    const postUser = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, {
          method: "POST",
          body: JSON.stringify({
            email: email,
            password: password,
          }),
          headers: { "Content-Type": "application/json" },
        });

        if (!res.ok) {
          throw new Error("error");
        } else {
          const data = await res.json();
          dispatch(
            AuthAction.adduser({
              email: data.user.email,
              countryCode: data.user.countryCode,
              phoneNumber: data.user.phoneNumber,
              type: data.user.type,
            })
          );
          dispatch(AuthAction.addtoken(data.accessToken));
          localStorage.setItem(
            "userData",
            JSON.stringify({ user: data.user, token: data.accessToken })
          );
          if (!res.ok) {
            throw new Error("error in data");
          }
          toast.success("sucess");
          navigate("/admin/dashboard");
          resetEmail();
          resetPassword();
        }
      } catch (error) {
        toast.error("Error in Login");
      }
    };
    postUser();
  };
  useEffect(() => {
    if (user && token) {
      navigate("/admin/dashboard");
    }
  });
  if (!user || !token) {
    return (
      <div className={classes.container}>
        <form className={classes.form} onSubmit={formSubmitHandler} noValidate>
          <img src={Logo} alt="base logo" />
          <p className={classes.header}>Sign In</p>
          <p className={classes.des}>Enter your email and password to Sign In</p>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
          />
          {emailInputHasError && <span className={classes.valid}>Enter valid Email</span>}
          <input
            type="password"
            placeholder="Current Password"
            value={password}
            onChange={passwordChangeHandler}
            onBlur={passwordBlurHandler}
          />
          {passwordInputHassError && <span className={classes.valid}>Enter valid Password</span>}
          <div className={classes.actions}>
            <div className={classes.check}>
              <input type="checkbox" />
              <span className="checkmark">Remember me</span>
            </div>
            <p onClick={ForgetHandler}>Forget Password</p>
          </div>
          <button className={classes.btn} disabled={!formIsValid}>
            sign in
          </button>

          <p className={classes.footer}>
            Don't have an account? <span>Sign Up</span>
          </p>
        </form>
      </div>
    );
  }
};

export default Form;
