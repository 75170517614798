import React from "react";
import Logo from "../../../assets/images/footerLogo.png";
import Apple from "../../../assets/images/Apple.png";
import Andriod from "../../../assets/images/andriod.png";

import classes from "./LFooter.module.css";
const LFooter = () => {
  return (
    <footer>
      <img src={Logo} alt="base Logo" style={{ marginBottom: "30px" }} />
      <p>Coming soon on</p>
      <div className={classes.images}>
        <img src={Apple} />
        <img src={Andriod} />
      </div>
      <span> @ 2022 Base. All rights reserved</span>
    </footer>
  );
};

export default LFooter;
