import { Edit, LocalMall, PeopleAlt, Person } from "@mui/icons-material";
import ComplexStatisticsCard from "components/mui/mui-card";
import HorizontalBarChart from "components/mui/mui-chart/bar-chart/horizontal-bar-chart";
import VerticalBarChart from "components/mui/mui-chart/bar-chart/vertical-bar-chart";
import DefaultDoughnutChart from "components/mui/mui-chart/doughnut-chart";
import DefaultLineChart from "components/mui/mui-chart/line-charts";
import SalesByCountry from "components/mui/SalesByCountry";

import React from "react";
import { useNavigate } from "react-router-dom";
import DataICon from "./DashboardIcon";
import classes from "./DashboardData.module.css";
import colors from "assets/colors.js";
import { Box } from "@mui/material";
import StatBox from "./StatBox/StatBox";

const DashboardData = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={classes.total}>
        <StatBox
          icon={<PeopleAlt sx={{ color: "#fff" }} />}
          type="Users"
          count="281"
          percent="55"
        />
        <StatBox
          icon={<DataICon />}
          type="Sellers"
          count="2,300"
          percent="3"
          back="linear-gradient(180deg, #E93B77 0%, #DA1F63 100%)"
        />
        <StatBox
          icon={<Person sx={{ color: "#FFF" }} />}
          type="Admins"
          count="34k"
          percent="1"
          back="linear-gradient(180deg, #63B967 0%, #4BA64F 100%)"
        />
        <StatBox
          icon={<LocalMall sx={{ color: "#FFF" }} />}
          type="product"
          count="34k"
          percent="1"
          back="linear-gradient(180deg, #63B967 0%, #4BA64F 100%)"
        />
        <StatBox
          type="Registries"
          count="250"
          back="linear-gradient(180deg, #439DEE 0%, #1E78E9 100%)"
        />
      </div>
      <div className={classes.statist}>
        <HorizontalBarChart
          title="Top Selling Category"
          chart={{
            labels: ["Clothes", "Electronics", "Home", "Pets", "Sports"],
            datasets: [
              {
                label: "Sales by age",
                color: "dark",
                data: [15, 20, 12, 60, 20, 15],
              },
            ],
          }}
        />
        <DefaultLineChart
          title="5,000 KD Total Profit"
          chart={{
            labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            datasets: [
              {
                label: "Mobile Apps",
                color: "info",
                data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
              },
              {
                label: "Website",
                color: "primary",
                data: [40, 80, 70, 90, 30, 90, 140, 130, 200],
              },
            ],
          }}
        />
        <DefaultDoughnutChart
          title="1,350 Total Orders"
          description="Today 30"
          chart={{
            labels: ["Creative Tim", "Github", "Bootsnipp", "Dev.to", "Codeinwp"],
            datasets: {
              label: "Projects",
              backgroundColors: ["error", `${colors.dark.main}`, "info", "light", "primary"],
              data: [15, 20, 12, 60, 20],
            },
          }}
        />
      </div>
      <div className={classes.statist}>
        <DefaultLineChart
          title="5,000 KD Total Profit"
          chart={{
            labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            datasets: [
              {
                label: "Mobile Apps",
                color: "info",
                data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
              },
              {
                label: "Website",
                color: "primary",
                data: [40, 80, 70, 90, 30, 90, 140, 130, 200],
              },
            ],
          }}
        />
        <VerticalBarChart
          title="App Traffic"
          chart={{
            labels: ["16-20", "21-25", "26-30", "31-36", "36-42", "42+"],
            datasets: [
              {
                label: "Sales by age",
                color: "dark",
                data: [15, 20, 12, 60, 20, 15],
              },
            ],
          }}
        />
        <DefaultDoughnutChart
          title="1,350 Total Orders"
          description="Today 30"
          chart={{
            labels: ["Creative Tim", "Github", "Bootsnipp", "Dev.to", "Codeinwp"],
            datasets: {
              label: "Projects",
              backgroundColors: ["error", `${colors.dark.main}`, "info", "light", "primary"],
              data: [15, 20, 12, 60, 20],
            },
          }}
        />
      </div>
      <div className={classes.map}>
        <SalesByCountry />
        <form>
          <header>
            <p>Send a Push Notification</p>
            <button>History</button>
          </header>
          <div className={classes.enter}>
            <label>Title</label>
            <input type="text" placeholder="Select Categories" />
          </div>
          <div className={classes.two}>
            <div>
              <label>User Type</label>
              <select name="cars" id="cars">
                <option value="volvo">Volvo</option>
                <option value="saab">Saab</option>
                <option value="mercedes">Mercedes</option>
                <option value="audi">Audi</option>
              </select>
            </div>
            <div>
              <label>Send To</label>
              <select name="cars" id="cars">
                <option value="volvo">Volvo</option>
                <option value="saab">Saab</option>
                <option value="mercedes">Mercedes</option>
                <option value="audi">Audi</option>
              </select>
            </div>
          </div>
          <div className={classes.desc}>
            <label htmlFor="w3review">Description</label>

            <textarea id="w3review" name="w3review"></textarea>
          </div>
          <div className={classes.btns}>
            <button className={classes.cancel}>Cancel</button>
            <button
              className={classes.add}
              onClick={() => {
                navigate("/allnotifications");
              }}
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default DashboardData;
