import React, { useEffect, useState } from "react";
import classes from "./LForm.module.css";
import Phone from "./Phone";
import EmailIcon from "./Email";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";

const LForm = () => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [pro, setPro] = useState("");
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    if (fname && lname && phone && email && pro) {
      setDisabled(true);
    }
  }, [fname, lname, phone, email, pro, disabled]);

  const submitHandler = (e) => {
    e.preventDefault();
    const data = {
      fname,
      lname,
      phone,
      email,
      pro,
    };
  };
  const data = `First Name : ${fname} 
  Last Name : ${lname} 
  Phone : ${phone}
  email : ${email}
  Project details : ${pro}
  `;
  return (
    <div className={classes.Larg} id="contact">
      <div className={classes.LForm}>
        <div className={classes.info}>
          <h2>Contact us</h2>
          <p>Fill up the form and our team will get back to you within 24 hours</p>
          <span>
            <Phone /> +01234567890
          </span>
          <span>
            <EmailIcon /> hello@landify.design
          </span>
        </div>
        <form
        // action=`mailto:info@example.com ? subject=Contact US & body=`
        >
          <div className={classes.Two}>
            <TextField
              label="First name"
              variant="standard"
              sx={{ width: "100%" }}
              value={fname}
              onChange={(e) => setFname(e.target.value)}
              required
            />
            <TextField
              label="Last name"
              variant="standard"
              sx={{ width: "100%" }}
              value={lname}
              onChange={(e) => setLname(e.target.value)}
              required
            />
          </div>
          <div className={classes.Two}>
            <TextField
              label="Phone number"
              variant="standard"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
            <TextField
              label="Email address"
              variant="standard"
              sx={{ width: "100%" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <TextField
            label="Project details"
            multiline
            rows={4}
            variant="standard"
            sx={{ width: "100%" }}
            value={pro}
            onChange={(e) => setPro(e.target.value)}
            required
          />
          {disabled && (
            <a
              href={`mailto:omarnerougmail.com.com?cc=name2@rapidtables.com&bcc=name3@rapidtables.com
                  &subject=Contact%20US
                  &body=${data}`}
            >
              Send Message
            </a>
          )}
        </form>
      </div>
    </div>
  );
};

export default LForm;
