import React from "react";
import classes from "./LBecome.module.css";
import Mobile from "../../../assets/images/PhoneMockup.png";
const LBecome = () => {
  return (
    <div className={classes.Larg} id="ourapp">
      <div className={classes.LBecome}>
        <div>
          <h2>Love what you find</h2>
          <p>Base shows you what you want, and what you really really want.</p>
        </div>

        <img src={Mobile} />
      </div>
    </div>
  );
};

export default LBecome;
