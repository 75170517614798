import React from "react";
import { Drawer, Box, Typography, IconButton, Button, Stack } from "@mui/material";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import classes from "./MenuNav.module.css";
import { useNavigate } from "react-router-dom";
const MenuNav = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const Navigate = useNavigate();
  return (
    <>
      <IconButton
        onClick={() => setIsDrawerOpen(true)}
        size="large"
        edge="start"
        color="inherit"
        aria-label="logo"
        className={classes.MobileNav}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="right" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <Box p={2} width="250px" role="presentation">
          <Typography variant="h6" component="div" sx={{ color: "#2d2d77" }}>
            Base
          </Typography>
          <nav
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              flex: "1",
            }}
          >
            <Button
              sx={{
                fontFamily: "Source Sans Pro, sans-serif",
                fontWeight: 500,
                color: "#111827",
                fontSize: "17px",
                textTransform: "capitalize",
              }}
              color="inherit"
              href="#about"
              onClick={() => setIsDrawerOpen(false)}
            >
              About us
            </Button>
            <Button
              sx={{
                fontFamily: "Source Sans Pro, sans-serif",
                fontWeight: 500,
                color: "#111827",
                fontSize: "17px",
                textTransform: "capitalize",
              }}
              onClick={() => setIsDrawerOpen(false)}
              color="inherit"
              href="#ourapp"
            >
              Our app
            </Button>
            <Button
              sx={{
                fontFamily: "Source Sans Pro, sans-serif",
                fontWeight: 500,
                color: "#111827",
                fontSize: "17px",
                textTransform: "capitalize",
              }}
              color="inherit"
              href="#become"
              onClick={() => setIsDrawerOpen(false)}
            >
              Become a vendor
            </Button>
            <Button
              sx={{
                fontFamily: "Source Sans Pro, sans-serif",
                fontWeight: 500,
                color: "#111827",
                fontSize: "17px",
                textTransform: "capitalize",
              }}
              color="inherit"
              href="#contact"
              onClick={() => setIsDrawerOpen(false)}
            >
              Contact Us
            </Button>
          </nav>
        </Box>
      </Drawer>
    </>
  );
};

export default MenuNav;
