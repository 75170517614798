import React from "react";
import Form from "../components/Login/Form/Form";

const Login = () => {
  return (
    <>
      <Form />
    </>
  );
};

export default Login;
