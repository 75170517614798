import React from "react";
import classes from "./Navbar.module.css";
const OrderIcon = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.icon}
    >
      <path
        d="M2.08329 7.99967H4.9016C5.4726 7.99967 5.9946 8.32229 6.24996 8.83301C6.50532 9.34373 7.02732 9.66634 7.59832 9.66634H12.4016C12.9726 9.66634 13.4946 9.34373 13.75 8.83301C14.0053 8.32229 14.5273 7.99967 15.0983 7.99967H17.9166M7.47209 1.33301H12.5278C13.4252 1.33301 13.8739 1.33301 14.27 1.46964C14.6204 1.59048 14.9394 1.78767 15.2042 2.04696C15.5035 2.34017 15.7042 2.74151 16.1055 3.54415L17.911 7.15509C18.0685 7.47008 18.1472 7.62757 18.2028 7.79263C18.2521 7.93922 18.2877 8.09006 18.3091 8.24323C18.3333 8.4157 18.3333 8.59178 18.3333 8.94395V10.6663C18.3333 12.0665 18.3333 12.7665 18.0608 13.3013C17.8211 13.7717 17.4387 14.1542 16.9683 14.3939C16.4335 14.6663 15.7334 14.6663 14.3333 14.6663H5.66663C4.26649 14.6663 3.56643 14.6663 3.03165 14.3939C2.56124 14.1542 2.17879 13.7717 1.93911 13.3013C1.66663 12.7665 1.66663 12.0665 1.66663 10.6663V8.94395C1.66663 8.59178 1.66663 8.4157 1.69077 8.24323C1.71221 8.09006 1.74782 7.93922 1.79714 7.79263C1.85268 7.62757 1.93142 7.47008 2.08892 7.15509L3.89439 3.54415C4.29571 2.7415 4.49638 2.34017 4.79575 2.04696C5.06048 1.78767 5.37955 1.59048 5.72987 1.46964C6.126 1.33301 6.5747 1.33301 7.47209 1.33301Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OrderIcon;
